import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93ILszah2VNpMeta } from "/app/pages/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93jK3Pyj8dSOMeta } from "/app/pages/blog/[...slug].vue?macro=true";
import { default as indexgWaMlevJ7RMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slug_93xLXhDQXtR0Meta } from "/app/pages/careers/[...slug].vue?macro=true";
import { default as indexpW1WXp0mgAMeta } from "/app/pages/careers/index.vue?macro=true";
import { default as datenschutz8Idu2tRmArMeta } from "/app/pages/datenschutz.vue?macro=true";
import { default as kontakt_danke99KCLk5eAiMeta } from "/app/pages/kontakt_danke.vue?macro=true";
import { default as kontaktzJWjXi4cLvMeta } from "/app/pages/kontakt.vue?macro=true";
import { default as _91tag_93y8baCxTrLpMeta } from "/app/pages/tags/[tag].vue?macro=true";
import { default as testL3AVDvhyXeMeta } from "/app/pages/test.vue?macro=true";
import { default as component_45stubnO9MU04yTUMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnO9MU04yTU } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "slug___de",
    path: "/de/:slug(.*)*",
    meta: _91_46_46_46slug_93ILszah2VNpMeta || {},
    component: () => import("/app/pages/[...slug].vue")
  },
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    meta: _91_46_46_46slug_93ILszah2VNpMeta || {},
    component: () => import("/app/pages/[...slug].vue")
  },
  {
    name: "slug___es",
    path: "/es/:slug(.*)*",
    meta: _91_46_46_46slug_93ILszah2VNpMeta || {},
    component: () => import("/app/pages/[...slug].vue")
  },
  {
    name: "blog-slug___de",
    path: "/de/blog/:slug(.*)*",
    meta: _91_46_46_46slug_93jK3Pyj8dSOMeta || {},
    component: () => import("/app/pages/blog/[...slug].vue")
  },
  {
    name: "blog-slug___en",
    path: "/en/blog/:slug(.*)*",
    meta: _91_46_46_46slug_93jK3Pyj8dSOMeta || {},
    component: () => import("/app/pages/blog/[...slug].vue")
  },
  {
    name: "blog-slug___es",
    path: "/es/blog/:slug(.*)*",
    meta: _91_46_46_46slug_93jK3Pyj8dSOMeta || {},
    component: () => import("/app/pages/blog/[...slug].vue")
  },
  {
    name: "blog___de",
    path: "/de/blog",
    meta: indexgWaMlevJ7RMeta || {},
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "blog___en",
    path: "/en/blog",
    meta: indexgWaMlevJ7RMeta || {},
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "blog___es",
    path: "/es/blog",
    meta: indexgWaMlevJ7RMeta || {},
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "careers-slug___de",
    path: "/de/careers/:slug(.*)*",
    meta: _91_46_46_46slug_93xLXhDQXtR0Meta || {},
    component: () => import("/app/pages/careers/[...slug].vue")
  },
  {
    name: "careers-slug___en",
    path: "/en/careers/:slug(.*)*",
    meta: _91_46_46_46slug_93xLXhDQXtR0Meta || {},
    component: () => import("/app/pages/careers/[...slug].vue")
  },
  {
    name: "careers-slug___es",
    path: "/es/careers/:slug(.*)*",
    meta: _91_46_46_46slug_93xLXhDQXtR0Meta || {},
    component: () => import("/app/pages/careers/[...slug].vue")
  },
  {
    name: "careers___de",
    path: "/de/careers",
    meta: indexpW1WXp0mgAMeta || {},
    component: () => import("/app/pages/careers/index.vue")
  },
  {
    name: "careers___en",
    path: "/en/careers",
    meta: indexpW1WXp0mgAMeta || {},
    component: () => import("/app/pages/careers/index.vue")
  },
  {
    name: "careers___es",
    path: "/es/careers",
    meta: indexpW1WXp0mgAMeta || {},
    component: () => import("/app/pages/careers/index.vue")
  },
  {
    name: "datenschutz___de",
    path: "/de/datenschutz",
    meta: datenschutz8Idu2tRmArMeta || {},
    component: () => import("/app/pages/datenschutz.vue")
  },
  {
    name: "datenschutz___en",
    path: "/en/datenschutz",
    meta: datenschutz8Idu2tRmArMeta || {},
    component: () => import("/app/pages/datenschutz.vue")
  },
  {
    name: "datenschutz___es",
    path: "/es/datenschutz",
    meta: datenschutz8Idu2tRmArMeta || {},
    component: () => import("/app/pages/datenschutz.vue")
  },
  {
    name: "index___de",
    path: "/de",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "kontakt_danke___de",
    path: "/de/kontakt_danke",
    meta: kontakt_danke99KCLk5eAiMeta || {},
    component: () => import("/app/pages/kontakt_danke.vue")
  },
  {
    name: "kontakt_danke___en",
    path: "/en/kontakt_danke",
    meta: kontakt_danke99KCLk5eAiMeta || {},
    component: () => import("/app/pages/kontakt_danke.vue")
  },
  {
    name: "kontakt_danke___es",
    path: "/es/kontakt_danke",
    meta: kontakt_danke99KCLk5eAiMeta || {},
    component: () => import("/app/pages/kontakt_danke.vue")
  },
  {
    name: "kontakt___de",
    path: "/de/kontakt",
    meta: kontaktzJWjXi4cLvMeta || {},
    component: () => import("/app/pages/kontakt.vue")
  },
  {
    name: "kontakt___en",
    path: "/en/kontakt",
    meta: kontaktzJWjXi4cLvMeta || {},
    component: () => import("/app/pages/kontakt.vue")
  },
  {
    name: "kontakt___es",
    path: "/es/kontakt",
    meta: kontaktzJWjXi4cLvMeta || {},
    component: () => import("/app/pages/kontakt.vue")
  },
  {
    name: "tags-tag___de",
    path: "/de/tags/:tag()",
    meta: _91tag_93y8baCxTrLpMeta || {},
    component: () => import("/app/pages/tags/[tag].vue")
  },
  {
    name: "tags-tag___en",
    path: "/en/tags/:tag()",
    meta: _91tag_93y8baCxTrLpMeta || {},
    component: () => import("/app/pages/tags/[tag].vue")
  },
  {
    name: "tags-tag___es",
    path: "/es/tags/:tag()",
    meta: _91tag_93y8baCxTrLpMeta || {},
    component: () => import("/app/pages/tags/[tag].vue")
  },
  {
    name: "test___de",
    path: "/de/test",
    meta: testL3AVDvhyXeMeta || {},
    component: () => import("/app/pages/test.vue")
  },
  {
    name: "test___en",
    path: "/en/test",
    meta: testL3AVDvhyXeMeta || {},
    component: () => import("/app/pages/test.vue")
  },
  {
    name: "test___es",
    path: "/es/test",
    meta: testL3AVDvhyXeMeta || {},
    component: () => import("/app/pages/test.vue")
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/developer/crm",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/dev",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/developer/crm/*",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/affiliate/info",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/imprint",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/imprint",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/de/contact",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en/contact",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/jobs",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/karriere",
    component: component_45stubnO9MU04yTU
  }
]