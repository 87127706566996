<template>
  <div>
    <div class="contact-socials py-3">
      <a
        href="mailto:info@42he.com"
        class="button"
        target="_blank"
      >
        <span>info@42he.com</span>
      </a>
      <a
        href="https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2F42he.com%2Fde%2Fcareers%2F0BfC-Fullstack-Entwickler-m-w-"
        class="button"
        target="_blank"
      >
        <img src="/img/socials/linkedin.svg" class="contact-socials-icon" alt="">
        <span>LinkedIn</span>
      </a>
      <a
        href="https://www.facebook.com/sharer/sharer.php?t=Fullstack+Rails+Entwickler+%28m%2Fw%2Fd%29&u=https%3A%2F%2F42he.com%2Fde%2Fcareers%2F0BfC-Fullstack-Entwickler-m-w-"
        class="button"
        target="_blank"
      >
        <img src="/img/socials/facebook.svg" class="contact-socials-icon" alt="">
        <span>Facebook</span>
      </a>
    </div>
    <div class="has-text-centered py-5">
      <h1 class="title is-size-2 is-size-4-mobile has-text-dark">
        Terms and privacy
      </h1>
      <p>
        Please see our
        <a href="https://centralStationCRM.com/agb" target="_blank" rel="noopener noreferrer">terms and conditions</a>
        (GER). Please see our
        <a href="https://centralStationCRM.com/datenschutz" target="_blank" rel="noopener noreferrer">privacy statement</a>
        (GER).
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.contact-socials {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.contact-socials-icon {
  height: 20px;
  padding-right: 10px;
}
</style>
